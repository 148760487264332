<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Channel" />
        {{ channel.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">
        {{ channel.cid }}
      </div>
      <p class="mt-3">در این قسمت می‌توانید تنظیمات ربات را در کانال خود انجام دهید</p>
    </template>
    <template #default>
      <div class="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
        <template v-for="(item, index) in options" :key="index">
          <component
            :is="item.link ? 'router-link' : 'div'"
            :to="item.link"
            @[item.createPost&&`click`]="createPost"
            class="
              p-3
              bg-blueGray-100
              border-blueGray-200
              hover:bg-blueGray-200
              border
              rounded-md
              flex
              items-center
              cursor-pointer
            "
          >
            <div class="text-lg font-bold w-full">
              {{ item.text }}
            </div>
            <img :src="item.image" class="mx-2" />
          </component>
        </template>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
export default {
  components: { BodySimple, Icon },
  data() {
    return {
      firstLoading: true,
      error: [],
      channel: {},
      creatingPost: false,
      list_pro: {
        silver: "نقره ای",
        golden: "طلایی",
      },
    };
  },
  computed: {
    options() {
      return [
        {
          image: require("@/assets/images/icons8-adjustment-96.png"),
          text: "تنظیمات اصلی",
          link: {
            name: "ChannelOwnerSettingMain",
            params: { id: this.$route.params.id, channelId: this.channel?.id ?? 0 },
          },
        },
        {
          image: require("@/assets/images/icons8-schedule-mail-96.png"),
          text: "ارسال پست",
          createPost: true,
          // link: {
          //   name: "ChannelOwnerSettingPost",
          //   params: {
          //     id: this.$route.params.id,
          //     channelId: this.channel?.id ?? 0,
          //     postId: 321,
          //   },
          // },
        },
        {
          image: require("@/assets/images/icons8-group-message-96.png"),
          text: "آمار پست ها",
          link: {
            name: "ChannelOwnerSettingPosts",
            params: { id: this.$route.params.id, channelId: this.channel?.id ?? 0 },
          },
        },
        {
          image: require("@/assets/images/icons8-discussion-forum-96.png"),
          text: "صف ها",
          link: {
            name: "ChannelOwnerSettingQueues",
            params: { id: this.$route.params.id, channelId: this.channel?.id ?? 0 },
          },
        },
        {
          image: require("@/assets/images/icons8-team-96.png"),
          text: "کاربران",
          link: {
            name: "ChannelOwnerSettingShowUsers",
            params: { id: this.$route.params.id, channelId: this.channel?.id ?? 0 },
          },
        },
        {
          image: require("@/assets/images/icons8-admin-settings-male-96.png"),
          text: "مدیران",
          link: {
            name: "ChannelOwnerSettingShowAdmins",
            params: { id: this.$route.params.id, channelId: this.channel?.id ?? 0 },
          },
        },
        // {
        //   image: require("@/assets/images/icons8-membership-card-96.png"),
        //   text: "مدیریت اشتراک ها",
        //   link: {
        //     name: "ChannelOwnerSettingQueues",
        //     params: { id: this.$route.params.id, channelId: this.channel?.id ?? 0 },
        //   },
        // },
        {
          image: require("@/assets/images/icons8-vip-96.png"),
          text: "VIP",
          link: {
            name: "ChannelOwnerSettingVip",
            params: { id: this.$route.params.id, channelId: this.channel?.id ?? 0 },
          },
        },
        {
          image: require("@/assets/images/icons8-money-transfer-96.png"),
          text: "درامد از کانال",
          link: {
            name: "ChannelOwnerSettingIncome",
            params: { id: this.$route.params.id, channelId: this.channel?.id ?? 0 },
          },
        },
        {
          image: require("@/assets/images/icons8-bot-96-blue.png"),
          text: "دسترسی‌های ربات",
          link: {
            name: "ChannelOwnerSettingBot",
            params: { id: this.$route.params.id, channelId: this.channel?.id ?? 0 },
          },
        },
      ];
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      $this.$axios
        .get("api/owner-channels/" + $this.$route.params.channelId, {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.channel = response.data.channel;
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.firstLoading = false;
        });
    },
    createPost() {
      let $this = this;
      $this.$notify({ text: "در حال ساخت پست", duration: 2000 });
      if (!$this.creatingPost) {
        $this.creatingPost = true;
        $this.$axios
          .post("api/owner-channels/" + $this.$route.params.channelId + "/post", {
            id: $this.$store.state.user.id,
          })
          .then(function (response) {
            // console.log(response);
            $this.$notify({ text: "پست ایجاد شد", type: "success" });
            $this.$router.push({
              name: "ChannelOwnerSettingPost",
              params: {
                id: $this.$route.params.id,
                channelId: $this.channel?.id,
                postId: response.data.postId,
              },
            });
          })
          .catch(function (error) {
            $this.errors = error.data;
            $this.creatingPost = false;
            $this.$notify({ text: "خطا", type: "error" });
          })
          .finally(function () {
            $this.firstLoading = false;
          });
      }
    },
  },
};
</script>
